var colorPicker = colorPicker || {};
(function($){

	colorPicker.cssClasses = {
		"selected": "color-picker-selected",
		"hover": "color-picker-hover",
		"focus": "color-picker-focus",
		"choiceSelected": "color-picker-choice-selected",
		"choiceHover": "color-picker-choice-hover",
		"choiceFocus": "color-picker-choice-focus",
		"darkColor" : "color-picker-swatch--dark",
		"lightColor" : "color-picker-swatch--light"
	};

	colorPicker.swatchHTML = function( color ) {
		var colorText = ( typeof color === 'undefined' || !color || color === '' ) ? colorPickerStrings.pickColor : color.toString();
		var prefix = colorPickerStrings.color;
		var bgStyleAtt = ( color ) ? 'style="background-color:'+color+';"' : '';
		return [
			'<span class="color-picker-swatch">',
				'<span class="color-picker-swatch-color-wrap">',
					'<span class="color-picker-swatch-color" '+bgStyleAtt+'><i></i></span>',
				'</span>',
				'<span class="color-picker-swatch-text-wrap">',
					'<span class="color-picker-swatch-text-prefix">' + prefix + '</span>',
					'<span class="color-picker-swatch-text">' + colorText + '</span>',
					'<span class="color-picker-swatch-text-suffix"></span>',
				'</span>',
			'</span>'
		].join('');
	}


	colorPicker.getFormMarkupVersion = function( form_or_id ){
		var formElementID;
		var formID = '';
		var $form;

		if ( typeof form_or_id === 'undefined' || ( typeof form_or_id === 'string' && form_or_id === '' ) || ( typeof form_or_id === 'number' && form_or_id <= 0 ) ) {
			formElementID = $('form[id^="gform_"]:first').attr('id');
			formID = ( window.hasOwnProperty('gf_get_form_id_by_html_id') ) ? window.gf_get_form_id_by_html_id( formElementID ) : formElementID.replace('gform_', '');
			$form = $('#' + formElementID);
		}
		else if ( form_or_id instanceof jQuery ) {
			formElementID = form_or_id.attr('id');
			formID = ( window.hasOwnProperty('gf_get_form_id_by_html_id') ) ? window.gf_get_form_id_by_html_id( formElementID ) : formElementID.replace('gform_', '');
			$form = form_or_id;
		}
		else if ( typeof form_or_id === 'string' && form_or_id.indexOf("gform_") !== -1 ) {
			formID = (window.hasOwnProperty('gf_get_form_id_by_html_id')) ? window.gf_get_form_id_by_html_id( form_or_id ) : form_or_id.replace('gform_', '');
			formElementID = "gform_" + formID;
			$form = $('#' + formElementID);
		}
		else {
			formID = (window.hasOwnProperty('gf_get_form_id_by_html_id')) ? window.gf_get_form_id_by_html_id( "gform_" + form_or_id.toString() ) : form_or_id.toString();
			formElementID = "gform_" + formID;
			$form = $('#' + formElementID);
		}

		if ( !$form.length ) {
			return $('.gform_body .gfield:first').is('li') ? 1 : 2;
		}

		return $form.find('.gform_body .gfield:first').is('li') ? 1 : 2;
	};

	colorPicker.isLegacyMarkup = function( form_or_id ) {
		return ( colorPicker.getFormMarkupVersion( form_or_id ) === 1 );
	}

	colorPicker.isLegacyMode = function() {
		var useNewFeatures = ( colorPickerVars.hasOwnProperty('useNewFeatures') && colorPickerVars.useNewFeatures.toString() === 'true');
		return !useNewFeatures;
	};

	colorPicker.$fieldChoices = function( $field ) {
		if ( typeof $field === 'undefined' || $field instanceof jQuery === false) {
			return [];
		}

		var choicesSelector = '.ginput_container .gfield_radio div[class*="gchoice"], .ginput_container .gfield_checkbox div[class*="gchoice"]:not(.gchoice_select_all)';// GF 2.5+
		if ( colorPicker.isLegacyMarkup( $field.closest('[id^="gform_wrapper_"]') ) ) {
			choicesSelector = '.ginput_container .gfield_radio li, .ginput_container .gfield_checkbox li:not(.gchoice_select_all)';
		}

		return $field.find(choicesSelector);
	};


	colorPicker.SelectedFields = function( form_id ){
		var $form = ( typeof form_id !== 'undefined' && form_id !== '' ) ? $('#gform_'+form_id) : $('.gform_wrapper form');
		$form.find('.color-picker-field').each(function(){
			var $pickerField = $(this);
			var $choices = colorPicker.$fieldChoices( $pickerField );
			var isTextField = ($pickerField.length && !$choices.length);
			if ($choices.length > 0) {
				$choices.find('input:checked').each(function() {
					var $input = $(this);
					var $choice = $input.closest('[class*="gchoice"]');// TODO: Update to just .gchoice ?
					$choice.addClass(colorPicker.cssClasses.choiceSelected);
				});
			}
			else if (isTextField) {
				var $input = $pickerField.find('.ginput_container_text > input');
				if ($input.length && $.trim( $input.val() ) !== '') {
					$input.addClass(colorPicker.cssClasses.selected);
				}
			}
		});
	};

	colorPicker.isLightColor = function(color, sensitive = true) {

		// Thanks to https://gist.github.com/larryfox/1636338

		// Variables for red, green, blue values
		var r, g, b, hsp;

		// Check the format of the color, HEX or RGB?
		if (color.match(/^rgb/)) {

			// If HEX --> store the red, green, blue values in separate variables
			color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

			r = color[1];
			g = color[2];
			b = color[3];
		}
		else {

			// If RGB --> Convert it to HEX: http://gist.github.com/983661
			color = +("0x" + color.slice(1).replace(
				color.length < 5 && /./g, '$&$&'));

			r = color >> 16;
			g = color >> 8 & 255;
			b = color & 255;
		}

		// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
		hsp = Math.sqrt(
			0.299 * (r * r) +
			0.587 * (g * g) +
			0.114 * (b * b)
		);

		// Using the HSP value, determine whether the color is light or dark
		let threshold = ( !colorPicker.isLegacyMode() || !sensitive ) ? 245 : 127.5;
		threshold = gform.applyFilters('gfcp_light_color_threshold', threshold, color, { r, g, b });

		return (hsp > threshold);

		/*
		if (hsp > 127.5) {
			return 'light';
		}
		else {
			return 'dark';
		}
		*/
	};

	colorPicker.rgb2hex = function(rgb){
		// thanks to https://jsfiddle.net/Mottie/xcqpF/1/light/
		rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		return (rgb && rgb.length === 4) ? "#" +
			("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
			("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
			("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
	};

	colorPicker.onChoiceKeyPress = function(e) {

		var TABKEY = 9;
		var SPACEKEY = 32;
		var $input = $(e.target);
		var $field = $input.closest('.gfield');

		if (e.keyCode === SPACEKEY && ( $input.is(':checkbox') || $input.is(':radio') ) ) {
			if ($input.is(':radio') && !$input.is(':checked')) {
				e.preventDefault();
				//e.stopImmediatePropagation();
				//$input.next('label').click();
			}
			else if ($input.is(':checkbox')) {
				e.preventDefault();
				e.stopImmediatePropagation();
				//$input.next('label').click();
			}
		}
		else if (e.keyCode === TABKEY && $field.hasClass('color-picker-field')) {
			var direction = (e.shiftKey) ? 'previous' : 'next';
			var $inputWrap = $input.closest('.color-picker-choice');

			if (direction === 'next') {
				if (!$inputWrap.is(':last-child')) {
					e.preventDefault();
					//e.stopImmediatePropagation();
					$inputWrap.next('.color-picker-choice').find('> input').focus();
				}
			}
			else if (direction === 'previous') {
				if (!$inputWrap.is(':first-child')) {
					e.preventDefault();
					//e.stopImmediatePropagation();
					$inputWrap.prev('.color-picker-choice').find('> input').focus();
				}
			}

		}

	};

	colorPicker.onColorPickerSelectAllClick = function(e) {
		var $toggle = ( !colorPicker.isLegacyMode() ) ? $(this) : $(this).prev('input');
		var $field = $toggle.closest('.gfield');
		var isChecked = $toggle.data('checked');
		$toggle.toggleClass( 'is-checked', isChecked );

		var $choices = (isChecked) ? $field.find('.color-picker-choice input:checked') : $field.find('.color-picker-choice input:not(:checked)');

		if ($choices.length) {
			$choices.each(function(n, choiceEl){
				var $choice = $(choiceEl);
				var $label = $choice.closest('.color-picker-choice').find('label');//$choice.next('label')
				colorPicker.onColorPickerLabelClick( $label );
			});
		}

		if ( typeof jetslothMatchHeights !== 'undefined' ) {
			setTimeout(function(){
				jetslothMatchHeights();
			});
		}
	};

	colorPicker.dispatchPicked = function(hex, ref) {
		$('body').trigger('gf-color-picker:picked', [ hex, ref ]);
		$('.gfcp_bg, .gfcp_bg_'+ref+', #gfcp_bg, #gfcp_bg_'+ref).css('background-color', hex);
		$('.gfcp_color, .gfcp_color_'+ref+', #gfcp_color, #gfcp_color_'+ref).css('color', hex);
		$('.gfcp_border, .gfcp_border_'+ref+', #gfcp_border, #gfcp_border_'+ref).css('border-color', hex);
		$('.gfcp_fill, .gfcp_fill_'+ref+', #gfcp_fill, #gfcp_fill_'+ref).css('fill', hex);
	};

	colorPicker.onColorPickerLabelClick = function( $label ) {

		var $field = $label.closest('.gfield');
		var isTextField = ($field.find('.ginput_container_text').length);

		if (!isTextField) {

			var $choice = $label.closest('[class*="gchoice"]');// TODO: Update to just .gchoice ?
			var $wrap = $choice.closest('.gfield_radio, .gfield_checkbox');
			var $input = $choice.find('input');

			var bgColor = $choice.find('.color-picker-swatch-color').css('background-color');
			var hex = ( typeof bgColor !== "undefined" ) ? colorPicker.rgb2hex( bgColor.toUpperCase() ) : "";
			if ( !hex ) {
				return;
			}

			if ($wrap.hasClass('gfield_radio')) {
				// radio
				$wrap.find('.'+colorPicker.cssClasses.choiceSelected).not($choice).removeClass(colorPicker.cssClasses.choiceSelected).find('input').prop('checked', false);
				$choice.addClass(colorPicker.cssClasses.choiceSelected);

				colorPicker.dispatchPicked(hex, $choice.closest('form').attr('id').split('_').pop() + '_' + $choice.closest('.gfield').attr('id').split('_').pop());

			}
			else if ($wrap.hasClass('gfield_checkbox')) {
				// checkbox
				if ( $input.is( ':checked' ) ) {
					$choice.addClass(colorPicker.cssClasses.choiceSelected);

					colorPicker.dispatchPicked(hex, $choice.closest('form').attr('id').split('_').pop() + '_' + $choice.closest('.gfield').attr('id').split('_').pop());

				}
				else {
					$choice.removeClass(colorPicker.cssClasses.choiceSelected);
				}
			}

		}

	};


	colorPicker.clearColor = function( $colorInput, joe ) {
		joe.set('#ffffff');

		var $field = $colorInput.closest('.gfield');
		var fieldUsesSwatchesPopup = $field.hasClass('color-picker-use-swatches-popup');

		var $choices = colorPicker.$fieldChoices( $field );
		var isTextField = ($field.length && !$choices.length);
		var $swatch = isTextField ? $field.find('.color-picker-swatch') : $colorInput.closest('.color-picker-choice').find('.color-picker-swatch');

		$colorInput.val("").attr('value', "").trigger('change');
		$colorInput.removeClass( colorPicker.cssClasses.selected )

		$swatch.addClass(colorPicker.cssClasses.lightColor);
		$swatch.find('.color-picker-swatch-color').css('background-color', "");
		$swatch.find('.color-picker-swatch-text').html(colorPickerStrings.pickColor);


		if ( !isTextField ) {
			var $choice = $colorInput.closest('.color-picker-choice');
			var $otherInput = $choice.find('input[value="gf_other_choice"]');
			if ( typeof gformToggleRadioOther !== "undefined" ) {
				gformToggleRadioOther( $otherInput.get(0) );
			}
			else {
				$otherInput.next('input').focus();
			}
			$choice.removeClass( colorPicker.cssClasses.choiceSelected )
		}

		if (fieldUsesSwatchesPopup) {
			var $popupTrigger = $field.find('.color-picker-swatches-popup-trigger');
			$popupTrigger.find('.color-picker-swatch-text-prefix').html( colorPickerStrings.color );
			$popupTrigger.find('.color-picker-swatch-text').html("");
			$popupTrigger.find('.color-picker-swatch-text-suffix').html( colorPickerStrings.pickColor );
			$popupTrigger.find('.color-picker-swatch-color').css('background-color', "");
			$popupTrigger.removeClass( colorPicker.cssClasses.choiceSelected );
			$popupTrigger.addClass(colorPicker.cssClasses.lightColor);
		}

		colorPicker.dispatchPicked("", $field.closest('form').attr('id').split('_').pop() + '_' + $field.attr('id').split('_').pop());
	};

	colorjoe.registerExtra('clear', function(p, joe, o) {
		var btn = document.createElement('a');
		btn.innerHTML = window.colorPickerStrings.clear;
		btn.href = '#';
		btn.classList.add('cp-clear');
		btn.addEventListener('click', function(e){
			e.preventDefault()
			var $input = $(this).closest('.gfield').find('.ginput_container > .color-picker-input');
			if ( !$input.length ) {
				$input = $(this).closest('.gfield').find('.ginput_container .color-picker-choice-other input[id$="_other"]');
			}
			if ( $input.length ) {
				colorPicker.clearColor( $input, joe );
				var $field = $input.closest('.gfield');
				if ( $field.hasClass('color-picker-use-swatches-popup') ) {
					$field.find('.color-picker-wrapper').removeClass('color-picker-active');
					var pickColorActionString = (typeof window['gf_colorpicker_action_label'] === 'function') ? window.gf_colorpicker_action_label(colorPickerStrings.pickColor) : colorPickerStrings.pickColor;
					$field.find('.color-picker-swatches-popup-trigger .color-picker-swatch-text').html(pickColorActionString);
				}
			}
			joe.hide();
		});
		p.appendChild(btn);
	});

	colorPicker.SetUpFields = function( form_id ){

		window.colorPickerStrings.pickColor = gform.applyFilters('gfcp_label_pick_color', window.colorPickerStrings.pickColor)
		window.colorPickerStrings.color = gform.applyFilters('gfcp_label_color', window.colorPickerStrings.color)
		window.colorPickerStrings.close = gform.applyFilters('gfcp_label_close', window.colorPickerStrings.close)
		window.colorPickerStrings.done = gform.applyFilters('gfcp_label_done', window.colorPickerStrings.done)
		window.colorPickerStrings.clear = gform.applyFilters('gfcp_label_clear', window.colorPickerStrings.clear)

		if (jQuery('.entry-details .detail-view .color-picker-swatch').length) {
			// Entry detail view, doesn't get the custom class on the "field" wrap (it's .detail-view not .gfield)
			// If we add the classes here, the rest of the script (and styles) will work
			jQuery('.entry-details .detail-view .color-picker-swatch').each(function(i){
				var $imgWrap = $(this);
				var $field = $imgWrap.closest('.detail-view');
				$field.addClass('color-picker-field');
			});
		}

		var $form = ( typeof form_id !== 'undefined' && form_id !== '' ) ? $('#gform_'+form_id) : $('.gform_wrapper form');

		// WooCommerce Gravity Forms Product Add-Ons compatibility
		if ( !$form.length && $('.gform_variation_wrapper').length ) {
			$form = $('.gform_variation_wrapper');
		}

		var pantoneLabelPrefix = window.colorPickerStrings.color;
		if (typeof window.gf_colorpicker_pantone_label_prefix === 'function') {
			pantoneLabelPrefix = window.gf_colorpicker_pantone_label_prefix(window.colorPickerStrings.color);
		}

		$form.find('.cp-theme--pantone .color-picker-swatch-text-prefix').html(pantoneLabelPrefix);


		var $pickerFields = $form.find('.color-picker-field');
		if ($pickerFields.length) {

			var pickColorActionString = (typeof window['gf_colorpicker_action_label'] === 'function') ? window.gf_colorpicker_action_label(colorPickerStrings.pickColor) : colorPickerStrings.pickColor;

			$pickerFields.each(function(i, fieldEl){

				var $pickerField = $(fieldEl);
				var fieldUsesSwatchesPopup = $pickerField.hasClass('color-picker-use-swatches-popup');

				var jmhId = $pickerField.attr('id').replace('field_', '');
				var jmhAttr = ( !colorPicker.isLegacyMode() && ( $pickerField.hasClass('cp-theme--pantone') || $pickerField.hasClass('cp-image--shadow-box') ) ) ? ' data-jmh="' + jmhId + '"' : '';

				//var $choices = ($pickerField.length) ? $pickerField.find('.gfield_radio li, .gfield_checkbox li') : [];
				var $choices = colorPicker.$fieldChoices( $pickerField );
				var isTextField = ($pickerField.length && !$choices.length);

				if ($choices.length > 0) {

					if (fieldUsesSwatchesPopup) {

						var prefixText = $pickerField.find('.color-picker-choice:first').find('.color-picker-swatch-text-prefix').html();
						if ( typeof prefixText === "undefined" ) {
							var pantoneLabelPrefix = window.colorPickerStrings.color;
							if (typeof window.gf_colorpicker_pantone_label_prefix === 'function') {
								pantoneLabelPrefix = window.gf_colorpicker_pantone_label_prefix(window.colorPickerStrings.color);
							}
							prefixText = pantoneLabelPrefix;
						}

						var suffixText = $pickerField.find('.color-picker-choice:first').find('.color-picker-swatch-text-suffix').html();
						if ( typeof suffixText === "undefined" ) {
							suffixText = "";
						}

						var insertSelector = ( $pickerField.hasClass('field_description_above') && $pickerField.find('.gfield_description').length ) ? '.gfield_description' : '.gfield_label';
						$pickerField.find( insertSelector ).after([
							'<span class="color-picker-swatches-popup-trigger">',
								'<span class="color-picker-swatch">',
									'<span class="color-picker-swatch-color-wrap">',
										'<span class="color-picker-swatch-color"><i></i></span>',
									'</span>',
									'<span class="color-picker-swatch-text-wrap"'+jmhAttr+'>',
										'<span class="color-picker-swatch-text-prefix">'+prefixText+'</span>',
										'<span class="color-picker-swatch-text">'+pickColorActionString+'</span>',
										'<span class="color-picker-swatch-text-suffix">'+suffixText+'</span>',
									'</span>',
								'</span>',
							'</span>'
						].join(''));

						if ( !isTextField ) {
							var $popCloseBtn = $('<button type="button" class="color-picker-swatches-popup-close"><span>' + colorPickerStrings.close + '</span></button>');
							$pickerField.find('.gfield_radio, .gfield_checkbox').append( $popCloseBtn );
							$popCloseBtn.on('click', function(e){
								e.preventDefault();
								$(this).closest('.gfield').find('.ginput_container').removeClass('color-picker-active');
							});
						}
					}

					var $selectAll = ( !colorPicker.isLegacyMarkup( $form ) ) ? $pickerField.find('button[id$="select_all"]') : $pickerField.find('label[id$="select_all"]');
					if ($selectAll.length) {
						$pickerField.find('.gfield_radio, .gfield_checkbox').addClass('color-picker-has-select-all');
						if ( !colorPicker.isLegacyMode() ) {
							// new mode
							$selectAll.data('label-select', '<span class="color-picker-select-all-icon"><i></i></span><span class="color-picker-select-all-label"'+jmhAttr+'>' + $selectAll.data('label-select') + '</span>');
							$selectAll.data('label-deselect', '<span class="color-picker-select-all-icon"><i></i></span><span class="color-picker-select-all-label"'+jmhAttr+'>' + $selectAll.data('label-deselect') + '</span>');
							$selectAll.html('<span class="color-picker-select-all-icon"><i></i></span><span class="color-picker-select-all-label"'+jmhAttr+'>' + $selectAll.html() + '</span>');
							if ( jmhAttr ) {
								$pickerField.find('.color-picker-swatch-text-wrap').attr('data-jmh', jmhId);
							}
							$selectAll.on('click', colorPicker.onColorPickerSelectAllClick);
						}
						else if ( !colorPicker.isLegacyMarkup( $form ) ) {
							// new markup but legacy mode
							$selectAll.data('label-select', '<i></i><span>' + $selectAll.data('label-select') + '</span>');
							$selectAll.data('label-deselect', '<i></i><span>' + $selectAll.data('label-deselect') + '</span>');
							$selectAll.html('<i></i><span>' + $selectAll.html() + '</span>');
							$selectAll.on('click', colorPicker.onColorPickerSelectAllClick);
						}
						else {
							// legacy mode and legacy markup
							$selectAll.find('input').on('change', colorPicker.onColorPickerSelectAllClick);
						}
					}

					$choices.each(function(){
						var $choice = $(this);

						$choice.addClass('color-picker-choice gform-theme__no-reset--children');
						var choiceInit = $choice.data('init');
						if (choiceInit !== true) {

							$choice.data('init', true);

							$choice.find( 'input:radio, input:checkbox' ).click( function() {
								var $this = $( this );
								var $thisChoice = $this.closest('.color-picker-choice');
								var $label = $this.closest('.color-picker-choice').find('label');//$this.siblings( 'label' )
								colorPicker.onColorPickerLabelClick( $label );
								var thisColor = $thisChoice.find('.color-picker-swatch-color').css('background-color');
								if ( thisColor ) {
									var isLight = colorPicker.isLightColor(thisColor);
									$thisChoice.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.lightColor, isLight);
									$thisChoice.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.darkColor, !isLight);
								}
							} );

							if ($choice.find('input[value="gf_other_choice"]').length) {
								$choice.addClass('color-picker-choice-other');

								$choice.prepend([
									'<span class="color-picker-swatch">',
										'<span class="color-picker-swatch-color-wrap">',
											'<span class="color-picker-swatch-color"><i></i></span>',
										'</span>',
										'<span class="color-picker-swatch-text-wrap"'+jmhAttr+'>',
											'<span class="color-picker-swatch-text-prefix"></span>',
											'<span class="color-picker-swatch-text">'+pickColorActionString+'</span>',
											'<span class="color-picker-swatch-text-suffix"></span>',
										'</span>',
									'</span>'
								].join(''));

								$choice.find('br').remove();

								var otherLabel = $choice.find('input:text').val();
								$('#'+$pickerField.attr('id')+'.cp-theme--pantone .color-picker-choice-other .color-picker-swatch-text-prefix').html(otherLabel);

								$choice.click( function() {
									var $selectedChoice = $(this);
									$selectedChoice.find('input:radio').prop('checked', true);

									if ( gformToggleRadioOther !== "undefined" ) {
										gformToggleRadioOther( $selectedChoice.find('input:radio').get(0) );
									}

									colorPicker.onColorPickerLabelClick( $selectedChoice.find('input:radio') );

									var $colorInput = $selectedChoice.find('input:text');

									var $choiceField = $selectedChoice.closest('.gfield');
									var choiceFieldHasSwatchesPopup = $choiceField.hasClass('color-picker-use-swatches-popup');

									var color = $colorInput.val();
									if (color === '' || color === 'Other') {
										color = '#ffffff';
									}
									var picker = $colorInput.data('picker');
									var $pickerEl = $('.color-picker-wrapper');
									var $pickerUi = ( $pickerEl.length ) ? $pickerEl.find('> div') : undefined;

									if (typeof picker === 'undefined') {

										$pickerEl = $('<div class="color-picker-wrapper gform-theme__no-reset--children"><div data-id="color-picker-picker"></div></div>');
										$pickerUi = $pickerEl.find('> div');
										$choiceField.append($pickerEl);
										picker = colorjoe.rgb($pickerUi.get(0), color, [
											'currentColor',
											['hex', {label:'Hex'}],
											['fields', {space: 'RGB'}],
											['close', {label: colorPickerStrings.done}],
											['clear']
										]);
										picker.on("done", function(thisPicker){
											color = thisPicker.hex();
											$colorInput.val(color).attr('value', color).trigger('change');
											if ( color ) {
												var isLight = colorPicker.isLightColor(color);
												$choice.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.lightColor, isLight);
												$choice.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.darkColor, !isLight);
												$choice.find('.color-picker-swatch-color').css('background-color', color);
											}
											$choice.find('.color-picker-swatch-text').html(color.toUpperCase());

											if (choiceFieldHasSwatchesPopup) {
												var $popupTrigger = $choiceField.find('.color-picker-swatches-popup-trigger');
												$popupTrigger.find('.color-picker-swatch-text-prefix').html( $choice.find('.color-picker-swatch-text-prefix').html() );
												$popupTrigger.find('.color-picker-swatch-text').html(color.toUpperCase());
												$popupTrigger.find('.color-picker-swatch-text-suffix').html( $choice.find('.color-picker-swatch-text-suffix').html() );
											}

											colorPicker.dispatchPicked(color.toUpperCase(), $choice.closest('form').attr('id').split('_').pop() + '_' + $choiceField.attr('id').split('_').pop());
										}).update();
										$colorInput.data('picker', picker);

									}

									// update position
									var windowWidth = $(window).width();
									var pickerWidth = $pickerUi.outerWidth();
									var pickerHalfWidth = pickerWidth * 0.5;
									var choiceWidth = $selectedChoice.outerWidth();
									var choiceHalfWidth = choiceWidth * 0.5;
									var posLeft = $selectedChoice.position().left;// local
									var offsetLeft = $selectedChoice.offset().left;// global

									var pickerLeft = (posLeft + choiceHalfWidth) - pickerHalfWidth;
									if (offsetLeft + choiceHalfWidth + pickerHalfWidth > windowWidth ) {
										// adjust if offscreen to the right
										//pickerLeft = ( posLeft + choiceWidth ) - pickerWidth;
										pickerLeft -= (offsetLeft + choiceHalfWidth + pickerHalfWidth) - windowWidth;
									}
									if ( (offsetLeft + choiceHalfWidth) - pickerHalfWidth < 0 ) {
										// adjust if offscreen to the left
										//pickerLeft = posLeft;
										pickerLeft = posLeft - offsetLeft;
									}

									if ( !fieldUsesSwatchesPopup ) {
										$pickerUi.css('left', Math.round(pickerLeft) + 'px');
									}

									var windowHeight = $(window).height();
									var bodyHeight = $('body').outerHeight();
									var pickerHeight = $pickerUi.outerHeight();
									var pickerHalfHeight = pickerHeight * 0.5;
									var choiceHeight = $selectedChoice.outerHeight();
									var choiceHalfHeight = choiceHeight * 0.5;
									var posTop = $selectedChoice.position().top;// local
									var offsetTop = $selectedChoice.offset().top;// global

									var pickerTop = (posTop + choiceHalfHeight) - pickerHalfHeight;
									if (offsetTop + choiceHalfHeight + pickerHalfHeight > bodyHeight ) {
										// adjust if offscreen to the bottom
										//pickerTop = ( posTop + choiceHeight ) - pickerHeight;
										pickerTop -= (offsetTop + choiceHalfHeight + pickerHalfHeight) - bodyHeight;
									}
									if ( (offsetTop + choiceHalfHeight) - pickerHeight < 0 ) {
										// adjust if offscreen to the top
										//pickerTop = posTop;
										pickerTop = posTop - offsetTop;
									}

									if ( !fieldUsesSwatchesPopup ) {
										$pickerUi.css('top', Math.round(pickerTop) + 'px');
									}

									// close other pickers
									$('.color-picker-input, .color-picker-choice-other input:text').each(function(){
										var thisPicker = jQuery(this).data('picker');
										if (typeof thisPicker !== 'undefined') {
											thisPicker.hide();
										}
									});

									// show this one
									picker.show();

									$choiceField.find('.color-picker-wrapper').addClass('color-picker-active');

								} );

								$choice.find('input:text').focus(function(){
									$(this).blur();
								});

							}

							var startColor = $choice.find('.color-picker-swatch-color').css('background-color');
							if ( startColor ) {
								var isLight = colorPicker.isLightColor(startColor);
								$choice.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.lightColor, isLight);
								$choice.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.darkColor, !isLight);
								if (fieldUsesSwatchesPopup) {
									$pickerField.find('.color-picker-swatches-popup-trigger .color-picker-swatch').toggleClass(colorPicker.cssClasses.lightColor, isLight);
									$pickerField.find('.color-picker-swatches-popup-trigger .color-picker-swatch').toggleClass(colorPicker.cssClasses.darkColor, !isLight);
								}
							}

						}
					});

					setTimeout(function(){
						colorPicker.SelectedFields( form_id );
					}, 100);

				}
				else if (isTextField) {

					var $input = $pickerField.find('.ginput_container_text > input');

					if ($input.length) {

						$input.addClass('color-picker-input');// TODO: new class

						var inputInit = $input.data('init');

						if (inputInit !== true) {

							var $swatch = $input.closest('.gfield').find('.color-picker-swatch');
							/*
							var $swatch = jQuery([
								'<span class="color-picker-swatch">',
									'<span class="color-picker-swatch-color"></span>',
									'<span class="color-picker-swatch-text"></span>',
								'</span>'
							].join(''));
							$swatch.insertAfter($input);
							*/

							$input.data('init', true);
							$input.focus(function(){
								$(this).blur();
							});

							$swatch.click( function() {

								var $colorInput = $(this).prev('input:text');
								var color = $colorInput.val();
								if (jQuery.trim(color) === '') {
									color = '#ffffff';
								}
								var picker = $colorInput.data('picker');
								var $pickerEl = $('.color-picker-wrapper');
								var $pickerUi = ( $pickerEl.length ) ? $pickerEl.find('> div') : undefined;
								var $inputField = $colorInput.closest('.gfield');

								if (typeof picker === 'undefined') {

									$pickerEl = $('<div class="color-picker-wrapper gform-theme__no-reset--children"><div data-id="color-picker-picker"></div></div>');
									$pickerUi = $pickerEl.find('> div');
									$inputField.append($pickerEl);
									picker = colorjoe.rgb($pickerUi.get(0), color, [
										'currentColor',
										['hex', {label:'Hex'}],
										['fields', {space: 'RGB'}],
										['close', {label: colorPickerStrings.done}],
										['clear']
									]);
									picker.on("done", function(thisPicker){
										color = thisPicker.hex();
										//console.log('%c'+color, 'background:'+color);
										$colorInput.val(color);
										if ( color ) {
											var isLight = colorPicker.isLightColor(color);
											$swatch.toggleClass(colorPicker.cssClasses.lightColor, isLight);
											$swatch.toggleClass(colorPicker.cssClasses.darkColor, !isLight);
											$swatch.find('.color-picker-swatch-color').css('background-color', color);
										}
										$swatch.find('.color-picker-swatch-text').html(color.toUpperCase());

										colorPicker.dispatchPicked(color.toUpperCase(), $colorInput.closest('form').attr('id').split('_').pop() + '_' + $inputField.attr('id').split('_').pop());

									}).update();
									$colorInput.data('picker', picker);

								}

								// update position
								var windowWidth = $(window).width();
								var pickerWidth = $pickerUi.outerWidth();
								var pickerHalfWidth = pickerWidth * 0.5;
								var swatchWidth = $swatch.outerWidth();
								var swatchHalfWidth = swatchWidth * 0.5;
								var posLeft = $swatch.position().left;// local
								var offsetLeft = $swatch.offset().left;// global

								var pickerLeft = (posLeft + swatchHalfWidth) - pickerHalfWidth;
								if (offsetLeft + swatchHalfWidth + pickerHalfWidth > windowWidth ) {
									// adjust if offscreen to the right
									//pickerLeft = ( posLeft + swatchWidth ) - pickerWidth;
									pickerLeft -= (offsetLeft + swatchHalfWidth + pickerHalfWidth) - windowWidth;
								}
								if ( (offsetLeft + swatchHalfWidth) - pickerWidth < 0 ) {
									// adjust if offscreen to the left
									//pickerLeft = posLeft;
									pickerLeft = posLeft - offsetLeft;
								}
								$pickerUi.css('left', Math.round(pickerLeft) + 'px');

								var windowHeight = $(window).height();
								var bodyHeight = $('body').outerHeight();
								var pickerHeight = $pickerUi.outerHeight();
								var pickerHalfHeight = pickerHeight * 0.5;
								var swatchHeight = $swatch.outerHeight();
								var swatchHalfHeight = swatchHeight * 0.5;
								var posTop = $swatch.position().top;// local
								var offsetTop = $swatch.offset().top;// global

								var pickerTop = (posTop + swatchHalfHeight) - pickerHalfHeight;
								if (offsetTop + swatchHalfHeight + pickerHalfHeight > bodyHeight ) {
									// adjust if offscreen to the bottom
									//pickerTop = ( posTop + swatchHeight ) - pickerHeight;
									pickerTop -= (offsetTop + swatchHalfHeight + pickerHalfHeight) - bodyHeight;
								}
								if ( (offsetTop + swatchHalfHeight) - pickerHeight < 0 ) {
									// adjust if offscreen to the top
									//pickerTop = posTop;
									pickerTop = posTop - offsetTop;
								}
								$pickerUi.css('top', Math.round(pickerTop) + 'px');

								// hide other pickers
								$('.color-picker-input, .color-picker-choice-other input:text').each(function(){
									var thisPicker = jQuery(this).data('picker');
									if (typeof thisPicker !== 'undefined') {
										thisPicker.hide();
									}
								});

								// show this one
								picker.show();

								$pickerField.find('.color-picker-wrapper').addClass('color-picker-active');
							} );

							var startColor = $input.val();
							var isLight = false;

							if ($.trim(startColor) === '' || startColor.substr(0, 1) !== '#') {
								startColor = $swatch.find('.color-picker-swatch-color').css('background-color');
								if (startColor.substring(0, 3) === 'rgb') {
									startColor = colorPicker.rgb2hex(startColor);
								}
								isLight = colorPicker.isLightColor(startColor);
								$swatch.toggleClass(colorPicker.cssClasses.lightColor, isLight);
								$swatch.toggleClass(colorPicker.cssClasses.darkColor, !isLight);
							}
							else {
								if ( startColor ) {
									isLight = colorPicker.isLightColor(startColor);
									$swatch.toggleClass(colorPicker.cssClasses.lightColor, isLight);
									$swatch.toggleClass(colorPicker.cssClasses.darkColor, !isLight);
									$swatch.find('.color-picker-swatch-color').css('background-color', startColor);
								}
								$swatch.find('.color-picker-swatch-text').html(startColor.toUpperCase());
							}

							var placeholder = $input.prop('placeholder');
							if (typeof placeholder !== 'undefined' && $.trim(placeholder) !== '') {
								var fieldID = $input.closest('.gfield').attr('id');
								$('#'+fieldID+'.cp-theme--pantone .color-picker-swatch .color-picker-swatch-text-prefix').html(placeholder);
							}

						}

					}

				}

			});


			$('body').on('click', '.color-picker-swatches-popup-trigger .color-picker-swatch', function(){
				var $trigger = $(this);
				var $field = $trigger.closest('.gfield');
				$field.find('.ginput_container').addClass('color-picker-active');
			});

			$('body').on('gf-color-picker:picked', function(e, hex, ref){
				var $field = $('#field_'+ref);
				if ($field.length && $field.hasClass('color-picker-use-swatches-popup') && hex !== "") {

					var $choice = $field.find('.ginput_container input:checked').closest('.color-picker-choice');
					var $choiceSwatch = $choice.find('.color-picker-swatch');

					var choiceTextPrefix = $choiceSwatch.find('.color-picker-swatch-text-prefix').html();
					var choiceText = $choiceSwatch.find('.color-picker-swatch-text').html();
					var choiceTextSuffix = $choiceSwatch.find('.color-picker-swatch-text-suffix').html();

					var $popupTrigger = $field.find('.color-picker-swatches-popup-trigger');
					$popupTrigger.addClass('color-picker-choice-selected');
					if ( hex ) {
						var isLight = colorPicker.isLightColor(hex);
						$popupTrigger.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.lightColor, isLight);
						$popupTrigger.find('.color-picker-swatch').toggleClass(colorPicker.cssClasses.darkColor, !isLight);
						$popupTrigger.find('.color-picker-swatch-color').css('background-color', hex);
					}
					$popupTrigger.find('.color-picker-swatch-text-prefix').html( choiceTextPrefix );
					$popupTrigger.find('.color-picker-swatch-text').html( choiceText );
					$popupTrigger.find('.color-picker-swatch-text-suffix').html( choiceTextSuffix );

					$field.find('.ginput_container').removeClass('color-picker-active');

				}
			});

			$('body').on('click', '.colorPicker .close', function(){
				var $field = $(this).closest('.gfield');
				if ($field.length) {
					$field.find('.color-picker-wrapper').removeClass('color-picker-active');
				}
			});


			if ( typeof jetslothMatchHeights !== 'undefined' ) {
				jetslothMatchHeights();
			}
			gform.doAction('gfcp_setup', form_id);

		}

	};

	colorPicker.ConditionalLogic = function( form_id ) {

		var $form = ( typeof form_id !== 'undefined' && form_id !== '' ) ? $('#gform_'+form_id) : $('.gform_wrapper form');
		// WooCommerce Gravity Forms Product Add-Ons compatibility
		if ( !$form.length && $('.gform_variation_wrapper').length ) {
			$form = $('.gform_variation_wrapper');
		}

		var $choicesField = $form.find('.color-picker-field');
		if ($choicesField.length) {
			$choicesField.each(function(){
				var $field = jQuery(this);
				if ($field.is(':hidden')) {
					$field.find('.color-picker-choice').removeClass(colorPicker.cssClasses.choiceSelected);
				}
			});
			if ( typeof jetslothMatchHeights !== 'undefined' ) {
				setTimeout(function(){
					jetslothMatchHeights();
				}, 10);
			}
		}
	};

	$(document).bind('gform_post_render', function(event, form_id, current_page){
		gform.addFilter('gftt_dynamic_colorpicker_choices_position', function(){
			return false;// only needed to be true for legacy markup
		});
		window.gformGetOptionLabel = colorPicker.gformGetOptionLabel;
		colorPicker.SetUpFields( form_id );
	});

	$(document).bind('gform_post_conditional_logic', function(event, form_id, fields, isInit){
		colorPicker.ConditionalLogic( form_id );
		colorPicker.SelectedFields( form_id );
	});

	$(document).on('gfcf/conversational/navigate/start gfcf/conversational/navigate/next gfcf/conversational/navigate/prev gfcf/conversational/navigate/error', function(){
		if ( jetslothMatchHeights ) {
			jetslothMatchHeights();
		}
	});

	colorPicker.gform_format_option_label = function(fullLabel, fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index) {

		var markup = [fullLabel];

		var $field = $('#gform_'+formId+' .gfield#field_'+formId+'_'+fieldId);
		if ($field.length && $field.hasClass('color-picker-field')) {

			var $allOptions = $field.find('[class*="gchoice"]');// TODO: Update to just .gchoice ?
			var $thisOption = (typeof index !== 'undefined' && index >= 0) ? $allOptions.eq(index) : $allOptions;// when index is not passed in or not valid, get all options

			$thisOption.each(function(){

				var $option = $(this);
				var $thisOptionLabel = $option.find('label');
				var $thisOptionInput = $option.find('input');
				var thisValueText = $thisOptionInput.attr('value');
				var thisOptionLabel = (thisValueText.indexOf('|') !== -1) ? thisValueText.substring(0, thisValueText.indexOf('|')) : '';
				var thisOptionColor = $thisOptionLabel.data('color');

				//var fallbackFieldLabel = $thisOptionLabel.find('.image-choices-choice-text').html();
				var fallbackFieldLabel = thisOptionLabel;

				if ( (typeof fieldLabel === 'undefined' || fieldLabel === '') && fallbackFieldLabel !== '') {
					fieldLabel = fallbackFieldLabel;
				}

				if ( priceLabel === fieldLabel ) {
					priceLabel = "";
				}

				var swatchModifierClass = thisOptionColor ? colorPicker.isLightColor(thisOptionColor) ? colorPicker.cssClasses.lightColor : colorPicker.cssClasses.darkColor : '';

				// TODO: Only apply if style is relevant?
				var prefix = (typeof window.gf_colorpicker_pantone_label_prefix !== 'undefined') ? gf_colorpicker_pantone_label_prefix(window.colorPickerStrings.color) : window.colorPickerStrings.color;

				var jmhId = $field.attr('id').replace('field_', '');
				var jmhAttr = ( $field.hasClass('cp-theme--pantone') || $field.hasClass('cp-image--shadow-box') ) ? ' data-jmh="' + jmhId + '"' : '';

				var bgStyleAtt = ( thisOptionColor ) ? 'style="background-color:'+thisOptionColor+';"' : '';

				markup = [
					'<span class="color-picker-swatch '+swatchModifierClass+'">',
						'<span class="color-picker-swatch-color-wrap">',
							'<span class="color-picker-swatch-color" '+bgStyleAtt+' data-color="'+thisOptionColor+'"><i></i></span>',
						'</span>',
						'<span class="color-picker-swatch-text-wrap"'+jmhAttr+'>',
							'<span class="color-picker-swatch-text-prefix">'+prefix+'</span>',
							'<span class="color-picker-swatch-text">',
								gform.applyFilters( 'gfcp_choice_text', fieldLabel, selectedPrice, price, formId, fieldId, index ),
								'<span class="color-picker-swatch-price">',
									gform.applyFilters( 'gfcp_choice_price', priceLabel, selectedPrice, price, formId, fieldId, index ),
								'</span>',
							'</span>',
							'<span class="color-picker-swatch-text-suffix"></span>',
						'</span>',
					'</span>'
				];

			});

			setTimeout(function(){
				if ( typeof jetslothMatchHeights !== 'undefined' ) {
					jetslothMatchHeights();
				}
			}, 100);

		}

		return gform.applyFilters( 'gfcp_choice_html', markup.join(''), fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index );
	}
	window.colorPicker_gform_format_option_label = colorPicker.gform_format_option_label;// legacy support

	window.gform_format_option_label = function(fullLabel, fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index) {

		if ( typeof gftt !== 'undefined' && typeof gftt.option_label_reinit !== 'undefined' ) {
			gftt.option_label_reinit( formId, fieldId );
		}

		// Added for cross compat with Image Choices
		var $field = $('#field_'+formId+'_'+fieldId);
		if ($field.length && $field.hasClass('image-choices-field') && typeof window.imageChoices_gform_format_option_label === 'function') {
			return window.imageChoices_gform_format_option_label(fullLabel, fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index);
		}

		return colorPicker.gform_format_option_label(fullLabel, fieldLabel, priceLabel, selectedPrice, price, formId, fieldId, index);

	};

	colorPicker.gformGetOptionLabel = function(element, selected_value, current_price, form_id, field_id) {

		element = $(element);

		// Added for cross compat with Image Choices
		if (element.closest('.gfield').hasClass('image-choices-field') && typeof window.imageChoices_gformGetOptionLabel === 'function') {
			return window.imageChoices_gformGetOptionLabel(element, selected_value, current_price, form_id, field_id);
		}

		var wrap = element.closest('[class*="gchoice"]');// TODO: Update to just .gchoice
		var index = wrap.index();

		var price = gformGetPrice(selected_value);
		var current_diff = element.attr('price');
		var original_label = element.html().replace(/<span(.*)<\/span>/i, "").replace(current_diff, "");

		var diff = gformGetPriceDifference(current_price, price);
		diff = gformToNumber(diff) == 0 ? "" : " " + diff;
		element.attr('price', diff);

		//don't add <span> for drop down items (not supported)
		var price_label = element[0].tagName.toLowerCase() == "option" ? " " + diff : "<span class='ginput_price'>" + diff + "</span>";
		var label = original_label + price_label;

		// calling hook to allow for custom option formatting
		if(window["gform_format_option_label"])
			label = gform_format_option_label(label, original_label, price_label, current_price, price, form_id, field_id, index);

		return label;
	};
	window.colorPicker_gformGetOptionLabel = colorPicker.gformGetOptionLabel;// legacy support
	window.gformGetOptionLabel = colorPicker.gformGetOptionLabel;

	colorPicker.setSelectedColor = function( formId, fieldId, hex ){
		if ( typeof formId === 'undefined' || typeof fieldId === 'undefined' ) {
			return;
		}
		if ( typeof hex === 'undefined' ) {
			hex = '#ffffff';
		}
		else {
			hex = hex.toString();
		}

		var $input = $('input#input_' + formId.toString() + '_' + fieldId.toString());// text input
		var standardInput = true;
		if ( !$input.length ) {
			standardInput = false;
			$input = $('input#input_' + formId.toString() + '_' + fieldId.toString() + '_other');// "other" choice for radio buttons
		}

		if ( !$input.length ) {
			return;
		}

		if ( !standardInput && gformToggleRadioOther !== "undefined" ) {
			gformToggleRadioOther( $input.get(0) );
		}

		$input.val( hex ).attr('value', hex);

		var $field = $input.closest('.gfield');
		var $swatch = ( !standardInput ) ? $input.closest('.color-picker-choice').find('.color-picker-swatch') : $field.find('.color-picker-swatch:last');

		if ( $swatch.length && hex ) {
			$swatch.find('.color-picker-swatch-color').css('background-color', hex);
			$swatch.find('.color-picker-swatch-text').text(hex);
		}

		if ( !standardInput ) {
			$field.find('input[value="gf_other_choice"]').prop('checked', true).trigger('change');
			$input.closest('.color-picker-choice').addClass(colorPicker.cssClasses.choiceSelected);
		}

	};

	// GravityWiz Populate Anything integration
	$(document).on( 'gppa_updated_batch_fields', function( event, formId, updatedFieldIds ) {
		var selector = updatedFieldIds.map(function(fieldId){ return '.color-picker-field#field_' + formId + '_' + fieldId; }).join(', ');
		var $pickerFields = $(selector);
		if ( !$pickerFields.length ) {
			return;
		}
		$pickerFields.each(function(f, fieldElem){
			var $field = $(fieldElem);
			var $swatches = $field.find('.color-picker-swatch');
			if ( !$swatches.length ) {
				var $choices = colorPicker.$fieldChoices( $field );
				var isTextField = ( !$choices.length );
				if ( !isTextField ) {
					$choices.each(function(i, choiceElem){
						var $choice = $(choiceElem);
						$choice.data('init', false);
						var $input = $choice.find('> input');
						var $label = $choice.find('> label');
						var color = $input.val();
						var swatchHTML = colorPicker.swatchHTML( color );
						var $swatch = $( swatchHTML );
						$label.prepend( $swatch );
					});
				}
				else {
					var $input = $field.find('.ginput_container_text > input');
					$input.data('init', false);
					var color = $input.val();
					var swatchHTML = colorPicker.swatchHTML( color );
					var $swatch = $( swatchHTML );
					$swatch.insertAfter( $input );
				}
			}
		});
		colorPicker.SetUpFields(formId);
	});



})(jQuery);
